/**
 * Licent
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { ApplicationController, useDebounce } from 'stimulus-use';

/**
 * Filter controller.
 */
export default class extends ApplicationController {
  /**
   * @return {Array}
   */
  static debounces = ['submit'];

  /**
   * Connect
   */
  connect() {
    useDebounce(this);
  }

  /**
   * Request submit.
   */
  submit() {
    this.element.requestSubmit();
  }
}
