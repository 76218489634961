/**
 * Licent
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Cursor controller.
 */
export default class extends Controller {
  static targets = ['cursor'];

  /**
   * Follow cursor position.
   *
   * @param {Event} event
   */
  cursor(e) {
    this.cursorTarget.style.transform = `translate3d(${e.clientX}px, ${e.clientY}px, 0)`;
  }
}
