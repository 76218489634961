/* eslint-disable no-param-reassign, class-methods-use-this */
/**
 * Licent
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Cookie from '../utils/cookie';

const escKey = (event, callback) => {
  if (event.defaultPrevented) {
    return;
  }

  const key = event.key || event.keyCode;

  if (key === 'Escape' || key === 'Esc' || key === 27) {
    callback();
  }
};

/**
 * Modal Controller.
 */
export default class extends Controller {
  static targets = ['close'];

  static values = {
    url: String,
    popup: Boolean,
  };

  escListener;

  formId = 0;

  /**
   * Cookie storage key
   *
   * @type {String}
   */
  storageKey = 'showPopup';

  /**
   * Mount.
   */
  connect() {
    this.formId = `form-${Math.random().toString(36).slice(2)}`;
    this.element.dataset.formId = this.formId;

    if (this.popupValue && Cookie.get(this.storageKey) !== 'closed') {
      this.element.classList.add('is-active');
    }
  }

  /**
   * Show modal
   *
   * @param {String} formId
   * @param {String} portalId
   * @param {Function} onFormReady
   */
  async show(formId = null, portalId = null, onFormReady = null) {
    this.element.classList.add('is-active');
    document.body.classList.add('no-scroll');
    this.closeTarget.focus();

    if (portalId && formId) {
      const request = await fetch(`${this.urlValue}hubspot-form?portalId=${portalId}&formId=${formId}`);
      const response = await request.text();

      const wrapper = document.querySelector(`[data-form-id="${this.formId}"] [data-modal-target="form"]`);
      if (wrapper) {
        wrapper.innerHTML = response;

        setTimeout(() => {
          // Find and evaluate all script tags within the element
          const scripts = wrapper.getElementsByTagName('script');
          for (let i = 0; i < scripts.length; i += 1) {
            eval(scripts[i].innerHTML); // eslint-disable-line no-eval
          }

          const form = wrapper.querySelector('form');
          if (form) {
            if (onFormReady !== null) {
              onFormReady(form);
            } else {
              this.onFormReady(form);
            }
          }
        }, 0);
      }
    }

    this.escListener = document.addEventListener('keyup', (e) => escKey(e, this.close.bind(this)));
    this.element.dispatchEvent(new Event('modal.show'));
  }

  /**
   * Close modal
   */
  close() {
    this.element.classList.remove('is-active');
    document.body.classList.remove('no-scroll');
    document.removeEventListener('keyup', this.escListener);
    this.element.dispatchEvent(new Event('modal.hide'));
  }

  /**
   * Set popupClosed cookie.
   */
  setCookie() {
    Cookie.set(this.storageKey, 'closed');
  }

  /**
   * On form ready.
   *
   * @param {Object} form
   */
  onFormReady(form) {
    const hsContext = form.querySelector('input[name="hs_context"]');
    if (hsContext) {
      const hsContextObject = JSON.parse(hsContext.value);
      hsContextObject.pageUrl = window.location.href;
      hsContextObject.pageName = document.title;
      hsContext.value = JSON.stringify(hsContextObject);
    }

    const location = form.querySelector('input[name="pagelocation"]');
    if (location) {
      location.value = window.location.href;
    }
  }
}
