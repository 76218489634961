/* eslint-disable class-methods-use-this */
/**
 * Licent
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { ApplicationController, useDebounce } from 'stimulus-use';

const { mapkit } = window;

/**
 * Hubspot Controller.
 */
export default class extends ApplicationController {
  static targets = ['query', 'establishments', 'form', 'label', 'success'];

  /**
   * @return {Object}
   */
  static values = {
    search: String,
  };

  /**
   * @return {Array}
   */
  static debounces = ['search'];

  /**
   * Initialize map.
   */
  initialize() {
    mapkit.init({
      authorizationCallback(done) {
        done(import.meta.env.VITE_MAPKIT_TOKEN);
      },
    });
  }

  /**
   * Connect
   */
  connect() {
    useDebounce(this);
  }

  /**
   * Submit the form
   *
   * @param {Event} e
   */
  async submit(e) {
    e.preventDefault();
    const response = await fetch(window.location.href, {
      method: 'POST',
      body: new FormData(this.element),
    });

    if (response.ok) {
      switch (e.params.form) {
        case 'd7be4d96-1d4e-4fa1-b4de-92db89761ac8':
          window.location.href = `${e.params.url}bedankt-afspraak-maken`;
          break;
        case '0db54a74-d19b-448f-bd6f-bac6db92f253':
          window.location.href = `${e.params.url}bedankt-bel-terug`;
          break;
        case 'de589735-86ec-4f8d-8bdc-854abfb81d6d':
          window.location.href = `${e.params.url}bedankt-contact`;
          break;
        case 'e9748589-bc69-4b74-b938-9fcdb587074a':
          window.location.href = `${e.params.url}bedankt-hyptoheek-berekenen`;
          break;
        case '1b8db95b-b822-474b-8123-a957a47a3c30':
          window.location.href = `${e.params.url}bedankt-bruto-netto-berekening`;
          break;
        default:
          this.formTarget.classList.add('hidden');
          this.successTarget.classList.remove('hidden');
          break;
      }
    }
  }

  /**
   * Search.
   *
   * @param {Event} e
   */
  async search(e) {
    const url = new URL(this.searchValue);
    url.searchParams.set('q', e.target.value);
    const response = await fetch(url.toString());
    const establishments = await response.json();
    if (establishments.length) {
      this.establishmentsTarget.innerHTML = establishments.map((establishment) => `<option>${establishment.title} ${establishment.subtitle}</option>`).join('');
    } else {
      this.establishmentsTarget.innerHTML = '<option disabled selected>Er zijn geen vestigingen gevonden.</option>';
    }
    this.establishmentsTarget.parentNode.classList.add('flex');
    this.establishmentsTarget.parentNode.classList.remove('hidden');
  }

  /**
   * Geocode.
   *
   * @param {Event} e
   */
  async geocode(e) {
    const [pin, loader] = e.target.children;
    pin.classList.add('hidden');
    loader.classList.remove('hidden');

    // Get current GPS position
    const position = await this.getPosition();

    // Send coords to server to retrieve current country
    const coordinates = new mapkit.Coordinate(position.coords.latitude, position.coords.longitude);
    const address = await this.getAddress(coordinates);

    // Set address in search field and search
    this.queryTarget.value = address?.results[0]?.locality;
    this.search({ target: this.queryTarget });

    pin.classList.remove('hidden');
    loader.classList.add('hidden');
  }

  /**
   * Get position.
   *
   * @return {Promise}
   */
  getPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

  /**
   * Get address from coordinates.
   *
   * @param {Coordinate} coordinates
   *
   * @return {Promise}
   */
  getAddress(coordinates) {
    const geocoder = new mapkit.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.reverseLookup(coordinates, (error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    });
  }
}
