/**
 * Licent
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Simple tab controller
 */
export default class extends Controller {
  static classes = ['active'];

  static targets = ['btn', 'tab'];

  /**
   * Add target class.
   */
  select(event) {
    const selectedTab = this.tabTargets.find(
      (element) => element.dataset.id === event.params.id,
    );

    if (selectedTab.classList.contains('hidden')) {
      // hide everything
      this.tabTargets.forEach((x) => x.classList.add('hidden'));
      this.btnTargets.forEach((x) => x.classList.remove(...this.activeClasses));

      // then show selected
      selectedTab.classList.remove('hidden'); // show current tab
      event.target.classList.add(...this.activeClasses); // activate current button
    }
  }
}
