/* eslint-disable class-methods-use-this */
/**
 * Licent
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

const { mapkit } = window;

/**
 * Controller for maps.
 */
export default class extends Controller {
  /**
   * @return {Object}
   */
  static values = {
    locations: Array,
  };

  /**
   * @var {Map}
   */
  map;

  /**
   * @var {Array}
   */
  annotations = [];

  /**
   * Initialize map.
   */
  initialize() {
    mapkit.init({
      authorizationCallback(done) {
        done(import.meta.env.VITE_MAPKIT_TOKEN);
      },
    });
  }

  /**
   * Connect map.
   */
  connect() {
    this.map = new mapkit.Map(this.element);
    this.map.removeItems(this.annotations);
    this.annotations = [];
    for (let i = 0; i < this.locationsValue.length; i += 1) {
      const location = this.locationsValue[i];
      const coords = new mapkit.Coordinate(location.latitude, location.longitude);
      const annotation = new mapkit.Annotation(coords, this.getMarker.bind(this, location), {
        data: { ...location },
        animates: false,
      });

      this.annotations.push(annotation);
    }
    this.map.showItems(this.annotations);
  }

  /**
   * Destroy map.
   */
  disconnect() {
    if (this.map !== undefined) {
      this.map.destroy();
    }
  }

  /**
   * Create marker.
   *
   * @param {Object} location
   *
   * @return {Element}
   */
  getMarker(location) {
    const element = document.createElement('div');
    element.className = 'map-marker';
    element.innerHTML = `<p>${location.title}<br>${location.subtitle}</p>`;

    return element;
  }
}
