/* eslint-disable class-methods-use-this */
/**
 * Licent
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Header controller.
 */
export default class extends Controller {
  static targets = ['child', 'image'];

  /**
   * On scroll.
   */
  onScroll() {
    this.element.classList.toggle('has-scrolled', window.scrollY >= 100);
  }

  /**
   * Toggle mobile menu.
   */
  toggleMobileMenu() {
    this.element.classList.toggle('menu-is-open');
  }

  /**
   * Toggle mobile submenu.
   *
   * @param {Event} event
   */
  toggleMobileSubmenu(target) {
    target.target.closest('li').classList.toggle(target.params.activeclass);
  }

  /**
   * Toggle children.
   *
   * @param {Event} event
   */
  toggleChildren({ params }) {
    this.childTargets.forEach((child) => {
      child.classList.toggle('hidden', +child.dataset.index !== params.index);
    });
  }
}
