/**
 * Licent
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * A11y controller.
 */
export default class extends Controller {
  static targets = ['dyslexiaBtn'];

  fontSize;

  minFontSize;

  maxFontSize;

  dyslexiaModeEnabled;

  /**
   * Init.
   */
  initialize() {
    const savedFontSize = sessionStorage.getItem('fontSize');
    const defaultFontSize = 16;

    this.fontSize = savedFontSize ? +savedFontSize : defaultFontSize;
    this.minFontSize = defaultFontSize;
    this.maxFontSize = 18;

    this.applyFontSize();

    const dyslexiaMode = sessionStorage.getItem('dyslexiaMode');
    if (dyslexiaMode === 'true') {
      this.toggleDyslexiaMode();
    }
  }

  /**
   * Zoom font-size.
   */
  zoom() {
    this.fontSize = (this.fontSize < this.maxFontSize) ? this.fontSize + 1 : this.minFontSize;
    this.applyFontSize();

    // Add to session storage
    sessionStorage.setItem('fontSize', this.fontSize);
  }

  /**
   * Apply font-size value to DOM.
   */
  applyFontSize() {
    document.documentElement.style.fontSize = `${this.fontSize}px`;
  }

  /**
   * Toggle dyslexia font.
   */
  toggleDyslexiaMode() {
    this.dyslexiaModeEnabled = !this.dyslexiaModeEnabled;
    this.dyslexiaBtnTarget.classList.toggle('is-active', this.dyslexiaModeEnabled);
    document.body.classList.toggle('dyslexic-mode');

    // Add to session storage
    sessionStorage.setItem('dyslexiaMode', this.dyslexiaModeEnabled);
  }
}
