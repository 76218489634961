/**
 * Licent
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Form Controller.
 */
export default class extends Controller {
  static targets = ['form', 'label', 'success'];

  /**
   * Submit the form
   */
  async submit(e) {
    e.preventDefault();
    const response = await fetch(window.location.href, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: new FormData(this.element),
    });
    const result = await response.json();

    if ('message' in result) {
      this.formTarget.classList.add('hidden');
      this.successTarget.classList.remove('hidden');
    }
  }

  /**
   * Update the file input label
   */
  file(e) {
    const file = e.target.files[0];
    this.labelTarget.textContent = file ? file.name : e.params.label;
  }
}
