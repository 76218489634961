/**
 * Licent
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Link Controller.
 */
export default class extends Controller {
  static outlets = ['modal'];

  static values = {
    portal: String,
    form: String,
  };

  /**
   * Show modal for links of type custom
   *
   * @param {Event} e
   */
  open(e) {
    e.preventDefault();
    this.modalOutlet.show(this.formValue, this.portalValue);
  }
}
