/* eslint-disable class-methods-use-this */
/**
 * Licent
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

const { mapkit } = window;

/**
 * Objects Controller.
 */
export default class extends Controller {
  static targets = ['map', 'object'];

  /**
  * @return {Array}
  */
  static classes = ['active'];

  /**
   * @var {Object}
   */
  static values = {
    objects: Array,
  };

  /**
   * @var {Map}
   */
  map;

  /**
   * @var {Array}
   */
  annotations = [];

  /**
   * Initialize map.
   */
  initialize() {
    mapkit.init({
      authorizationCallback(done) {
        done(import.meta.env.VITE_MAPKIT_TOKEN);
      },
    });
  }

  /**
   * Connect map.
   */
  connect() {
    this.map = new mapkit.Map(this.mapTarget);
    this.map.removeItems(this.annotations);
    this.annotations = [];
    for (let i = 0; i < this.objectsValue.length; i += 1) {
      const object = this.objectsValue[i];
      const coords = new mapkit.Coordinate(object.latitude, object.longitude);
      const annotation = new mapkit.Annotation(coords, this.getMarker.bind(this, object), {
        data: { ...object },
        animates: false,
      });

      this.annotations.push(annotation);

      annotation.addEventListener('select', (e) => {
        this.annotations.forEach((a) => {
          a.element.classList.toggle(this.activeClass, a.data.id === e.target.data.id);
        });

        this.objectTargets.forEach((o) => {
          o.classList.toggle('hidden', +o.dataset.id !== e.target.data.id);
        });
      });
    }
    this.map.showItems(this.annotations);
  }

  /**
   * Destroy map.
   */
  disconnect() {
    if (this.map !== undefined) {
      this.map.destroy();
    }
  }

  /**
   * Close object.
   *
   * @param {Event} e
   */
  close(e) {
    const target = e.target.closest('[data-id]');
    target.classList.add('hidden');

    this.annotations.forEach((a) => {
      a.element.classList.remove(this.activeClass);
      a.selected = false; // eslint-disable-line no-param-reassign
    });
  }

  /**
   * Create marker.
   *
   * @return {Element}
   */
  getMarker() {
    const element = document.createElement('div');
    element.className = 'map-marker';

    return element;
  }
}
