/**
 * Licent
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import { gsap } from '@gsap/business';
import { ScrollTrigger } from '@gsap/business/ScrollTrigger';
import splitLines from '../utils/split_lines';

gsap.registerPlugin(ScrollTrigger);

/**
 * GSAP controller.
 */
export default class extends Controller {
  static values = {
    type: {
      type: String,
      default: 'fade',
    },
    direction: String,
    factor: Number,
    delay: Number,
    start: {
      type: String,
      default: 'top 80%',
    },
  };

  /**
   * GSAP context.
   */
  gsapContext = gsap.context(() => { });

  /**
   * Connect.
   */
  connect() {
    this.gsapContext.add(async () => {
      // Fade animation on scroll (example)
      if (this.typeValue === 'title') {
        const { children } = await splitLines(this.element);

        gsap.set(this.element, { autoAlpha: 1 });
        let delayValue = 0.25;
        if (this.delayValue > 0) {
          delayValue = this.delayValue;
        }

        gsap.fromTo(children, {
          yPercent: this.directionValue === 'down' ? -101 : 101,
        }, {
          yPercent: 0,
          duration: 1.25,
          ease: 'power4.out',
          stagger: 0.2,
          delay: delayValue,
          scrollTrigger: {
            once: true,
            trigger: this.element,
            start: this.startValue,
          },
          onComplete: () => gsap.set(children, { clearProps: 'opacity,y' }),
        });
      }

      // Shape animations
      if (this.typeValue === 'shape') {
        const target = this.element.querySelector('svg');
        let factor = 50;
        if (this.factorValue) {
          factor = this.factorValue * 50;
        }

        if (target) {
          gsap.fromTo(target, {
            y: this.directionValue === 'down' ? -factor : factor,
          }, {
            y: this.directionValue === 'down' ? factor : -factor,
            force3D: true,
            delay: 1,
            scrollTrigger: {
              trigger: this.element,
              scrub: 1,
            },
          });
        }
      }

      // Simple fade animation
      if (this.typeValue === 'fade') {
        gsap.fromTo(this.element, {
          y: 30,
          opacity: 0,
        }, {
          opacity: 1,
          y: 0,
          duration: 1.25,
          ease: 'power4.out',
          delay: this.delayValue,
          scrollTrigger: {
            trigger: this.element,
            start: this.startValue,
          },
          onComplete: () => gsap.set(this.element, { clearProps: 'opacity,y' }),
        });
      }
    });
  }

  /**
   * Disconnect.
   */
  disconnect() {
    this.gsapContext.revert();
  }
}
