/**
 * Licent
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import 'pannellum';

const { pannellum } = window;

/**
 * Controller for panorama.
 */
export default class extends Controller {
  /**
   * @var {Object}
   */
  viewer;

  /**
   * Connect panorama.
   */
  connect() {
    this.viewer = pannellum.viewer(this.element, {
      type: 'equirectangular',
      panorama: this.element.dataset.src,
      autoLoad: true,
    });
  }

  /**
   * Disconnect.
   */
  disconnect() {
    this.viewer.destroy();
  }
}
