/**
 * Licent index blok
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Controller for index blok.
 */
export default class extends Controller {
  static targets = [
    'item', 'trigger',
  ];

  /**
   * Scroll to
   *
   * @param {Event} e
   */
  scrollTo(e) {
    this.itemTargets.forEach((item) => {
      if (item.id === e.target.dataset.indexItem) {
        item.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  /**
   * Toggle triggers
   */
  onScroll() {
    this.itemTargets.forEach((item) => {
      const isAboveThreshold = item.getBoundingClientRect().top <= 400;

      this.triggerTargets.forEach((trigger) => {
        if (trigger.dataset.indexItem === item.id) {
          trigger.classList.toggle('active', isAboveThreshold);
        }
      });
    });
  }
}
