/**
 * Licent
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Cookie from '../utils/cookie';

export default class extends Controller {
  /**
   * Cookie storage key
   *
   * @type {String}
   */
  storageKey = 'cookiePreferences';

  /**
   * Connect.
   */
  connect() {
    if (!Cookie.get(this.storageKey)) {
      this.element.classList.remove('hidden');
    }
  }

  /**
   * Deny cookies.
   *
   * @param {Event} e
   */
  deny(e) {
    e.preventDefault();

    Cookie.set(this.storageKey, 'denied');
    this.element.classList.add('hidden');
  }

  /**
   * Accept cookies.
   *
   * @param {Event} e
   */
  accept(e) {
    e.preventDefault();

    Cookie.set(this.storageKey, 'accepted');
    this.element.classList.add('hidden');
  }
}
