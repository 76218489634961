/**
 * Licent
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import { Swiper } from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';
import { gsap } from '@gsap/business';

Swiper.use([Autoplay, Navigation]);

/**
 * Swiper controller.
 */
export default class extends Controller {
  static targets = ['wrapper', 'parallax'];

  static values = {
    options: Object,
    mobileOnly: {
      type: Boolean,
      default: false,
    },
  };

  swiper;

  mouseX = 0;

  parallaxOffset = 0;

  gsap;

  /**
   * Mount controller.
   */
  connect() {
    this.updateSwiper();
  }

  /**
   * Unmount controller.
   */
  disconnect() {
    this.destroySwiper();
  }

  /**
   * Update swiper based on viewport size.
   */
  updateSwiper() {
    if (this.mobileOnlyValue && window.innerWidth >= 768) {
      if (this.swiper) {
        this.destroySwiper();
      }
    } else if (!this.swiper) {
      this.initializeSwiper();
    }
  }

  /**
   * Initialize swiper.
   */
  initializeSwiper() {
    this.swiper = new Swiper(this.wrapperTarget, {
      slidesPerView: 'auto',
      grabCursor: true,
      ...this.optionsValue,
    });

    this.swiper.enable();
    this.swiper.on('touchMove', this.onTouchMove.bind(this));
    this.swiper.on('touchEnd', this.onTouchEnd.bind(this));

    if (this.hasParallaxTarget) {
      const scaleFactor = 1.1;
      const originalHeight = this.parallaxTarget.offsetHeight;
      const scaledHeight = originalHeight * scaleFactor;
      this.parallaxOffset = (scaledHeight - originalHeight) / 2;
    }
  }

  /**
   * Destroy swiper.
   */
  destroySwiper() {
    if (this.swiper) {
      this.swiper.off('touchMove', this.onTouchMove.bind(this));
      this.swiper.off('touchEnd', this.onTouchEnd.bind(this));

      if (this.gsap) {
        this.gsap.kill();
      }

      this.swiper.destroy(true);
      this.swiper = undefined;
    }
  }

  /**
   * on Touch end swiper event handler.
   */
  onTouchEnd() {
    this.element.classList.remove('is-moving');

    if (this.hasParallaxTarget) {
      this.parallaxEffect(0);
    }
  }

  /**
   * on Touch move swiper event handler.
   *
   * @param {Object} swiper instance
   */
  onTouchMove(swiper) {
    this.element.classList.add('is-moving');

    if (this.hasParallaxTarget) {
      this.handleParallax(swiper);
    }
  }

  /**
   * Parallax effect on slider move.
   *
   * @param {Object} swiper instance
   */
  handleParallax(swiper) {
    this.mouseX = swiper.touches.currentX;

    let offset = ((window.innerWidth / 2) - this.mouseX) * 0.05;

    if (offset >= this.parallaxOffset) {
      offset = -this.parallaxOffset;
    }

    if (offset <= -this.parallaxOffset) {
      offset = this.parallaxOffset;
    }

    this.parallaxEffect(offset, 'linear');
  }

  /**
   * GSAP parallax effect.
   *
   * @param {Number} x
   * @param {String} ease
   */
  parallaxEffect(x, ease = 'power4.out') {
    this.gsap = gsap.to(this.parallaxTargets, {
      x,
      ease,
      force3D: true,
    });
  }
}
