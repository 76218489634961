/**
 * Licent
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Megamenu controller
 */
export default class extends Controller {
  static classes = ['active'];

  static targets = ['item', 'subnav', 'subitem', 'childnav'];

  /**
   * Hover menu item.
   *
   * @param {Event}  event
   */
  hover(event) {
    const selectedSubnav = this.subnavTargets.find(
      (element) => element.dataset.id === event.params.id,
    );
    const selectedChildnav = this.childnavTargets.find(
      (element) => element.dataset.id === event.params.id,
    );

    // Hide all child navigation (third level)
    this.childnavTargets.forEach((nav) => nav.classList.add('hidden'));

    // Remove 'active' class from second-level items
    this.subitemTargets.forEach((item) => {
      const link = item.querySelector('a');
      link.classList.remove(this.activeClass);
      item.classList.remove(this.activeClass);
    });

    // Logic for hovering over first-level item
    if (selectedSubnav && selectedSubnav.classList.contains('hidden')) {
      // Hide all second-level navigation
      this.subnavTargets.forEach((nav) => nav.classList.add('hidden'));

      // Remove 'active' class from first-level items
      this.itemTargets.forEach((item) => {
        const link = item.querySelector('a');
        link.classList.remove(this.activeClass);
        item.classList.remove(this.activeClass);
      });

      // Show selected second-level navigation and add 'active' class
      selectedSubnav.classList.remove('hidden');
      this.toggleActiveClass(event.target);
    }

    // Logic for hovering over second-level item
    if (selectedChildnav && selectedChildnav.classList.contains('hidden')) {
      // Show selected third-level navigation and add 'active' class
      selectedChildnav.classList.remove('hidden');
      this.toggleActiveClass(event.target);
    }
  }

  /**
   * Toggles the 'active' class on the closest list item (LI) of the provided target element.
   *
   * @param {HTMLElement} target - The element that triggered the hover event.
   */
  toggleActiveClass(target) {
    const listItem = target.closest('LI');
    if (listItem) {
      listItem.classList.add(this.activeClass);
    } else {
      target.classList.add(this.activeClass);
    }
  }
}
